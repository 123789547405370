
import { mapActions, mapGetters } from 'vuex';
import Footer from '../../footer/components/footer';

export default {
  components: {
    Footer,
  },
  async created() {
    await this.doFetchArticle(this.$route.params.id);
  },

  methods: {
    ...mapActions({
      doFetchArticle: 'blog/doFetchArticle',
    }),
    formatDescription(description){
      return description.split('\n')
    }
  },
  computed: {
    ...mapGetters({
      article: 'blog/article',
      loading: 'blog/loading',
    }),
    backgroundImageStyle() {
      if (this.article && this.article.image) {
        if (this.$vuetify.theme.dark)
          return (
            'background-image: linear-gradient(180deg,rgba(0,0,0,1) 0%,rgba(255, 255, 255, 0) 100%),  url(' +
            this.article.image +
            '); '
          );
        else
          return (
            'background-image: linear-gradient(180deg,rgba(255,255,255,0.3) 0%,rgba(255, 255, 255, 0) 100%),  url(' +
            this.article.image +
            '); '
          );
      } else if(this.article && !this.article.image){
        if (this.$vuetify.theme.dark)
          return (
            "background-image: linear-gradient(180deg,rgba(0,0,0,1) 0%,rgba(255, 255, 255, 0) 100%),  url('/images/blog/default-view.png'); "
            );
        else
          return (
            "background-image: linear-gradient(180deg,rgba(255,255,255,0.3) 0%,rgba(255, 255, 255, 0) 100%),  url('/images/blog/default-view.png'); "
          );
      } 
      else return '';
    },
  },
};
